import { useContext } from 'react';
import { createContext, useState } from 'react';

export const SnackbarContext = createContext({});

export const SEVERITY = {
    error: 'error', warning: 'warning', info: 'info', success: 'success'
}

const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({
        message: '',
        severity: SEVERITY.info,
        open: false,
    })

    const autoHideDuration = 3000

    const showSnackbar = ({ message, severity = SEVERITY.info, open = true }) => {
        setSnackbar({ message, severity, open })
    }

    const showSuccess = ({ message }) => {
        setSnackbar({ message, severity: SEVERITY.success, open: true })
    }

    const showError = ({ message }) => {
        setSnackbar({ message, severity: SEVERITY.error, open: true })
    }

    const showInfo = ({ message }) => {
        setSnackbar({ message, severity: SEVERITY.info, open: true })
    }

    const showWarning = ({ message }) => {
        setSnackbar({ message, severity: SEVERITY.warning, open: true })
    }

    const closeSnackbar = () => {
        setSnackbar(old => ({ ...old, open: false }))
    }

    return (
        <SnackbarContext.Provider value={{
            snackbar,
            showSnackbar,
            closeSnackbar,
            autoHideDuration,
            showSuccess,
            showError,
            showInfo,
            showWarning,
        }}>
            {children}
        </SnackbarContext.Provider >
    )
}

const useSnackbar = () => {
    const {
        snackbar,
        showSnackbar,
        closeSnackbar,
        autoHideDuration,
        showSuccess,
        showError,
        showInfo,
        showWarning, } = useContext(SnackbarContext)

    return {
        snackbar,
        showSnackbar,
        closeSnackbar,
        autoHideDuration,
        showSuccess,
        showError,
        showInfo,
        showWarning,
    }
}

export { SnackbarProvider, useSnackbar }