export const ROLE_CODES = {
    'ADMIN': 'ADMIN',
    'SUPERADMIN': 'SUPERADMIN',
}

export const SALUTATIONS = [
    { name: "Mr", code: "Mr" },
    { name: "Mrs", code: "Mrs" },
    { name: "Ms", code: "Ms" },
]

export const MASTER_STATUS_LIST = [
    { name: "All", value: 999 },
    { name: "Active", value: 1 },
    { name: "Disabled", value: 0 },
    { name: "Deleted", value: -1 },
]

export const EMPLOYEE_STATUS_LIST = [
    { name: "Active", value: 1 },
    { name: "Disabled", value: 0 },
    { name: "Relieved", value: -1 },
]

export const MASTER_STATUS_VALUE = {
    ALL: 999,
    ACTIVE: 1,
    DISABLED: 0,
    DELETED: -1,
}

export const GOALS_TYPE = [
    { name: "KPI", value: "kpi" },
    { name: "Skills and Competency", value: "skills_and_competency" },
    { name: "Assignment", value: "assignment" },
    { name: "Personal Development", value: "personal_development" },
]

export const REVIEW_INTERVALS = [
    { name: "Monthly", value: "monthly" },
    { name: "Quarterly", value: "quarterly" },
    { name: "Halfyearly", value: "halfyearly" },
    { name: "Yearly", value: "yearly" },
]

export const FREQUENCY = [
    { name: "Daily", value: "daily", description: "Once a day" },
    { name: "Weekly", value: "weekly", description: "Once a week" },
    { name: "Biweekly", value: "biweekly", description: "Once in 2 weeks" },
    { name: "Monthly", value: "monthly", description: "Once a month" },
    { name: "Quarterly", value: "quarterly", description: "Once in 3 months" },
    { name: "Halfyearly", value: "halfyearly", description: "Once in 6 months" },
    { name: "Yearly", value: "yearly", description: "Once a year" },
]

export const GOAL_STATUS_LIST = [
    { name: "Active", value: 'active' },
    { name: "Any", value: 'any' },
    { name: "Deleted", value: 'deleted' },
]

export const DUE_FOR_REVIEWS = [
    { name: "L1", value: "l1" },
    { name: "L2", value: "l2" },
    { name: "L3", value: "l3" },
    { name: "L1, L2", value: "l1,l2" },
]

export const JOBS = [
    { name: "All", value: "" },
    { name: "Success", value: 1 },
    { name: "Failed", value: 0 },
]

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_only_date = "DD-MM-YYYY";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = "Do MMM YYYY HH:mm:ss";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy = "Do MMM YYYY hh:mm a";
export const format_display_fancy_my = "MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_display_fancy_notime_withDayAndyear = "dddd, Do MMM YYYY";