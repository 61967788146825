// import { getToken } from "../Auth";
const AUTH_TOKEN = "auth_token"
const getToken = () => localStorage.getItem(AUTH_TOKEN)

const RestService = () => {
    const request = (method) => {
        return (url, body, isFormData, mockData) => {
            if (mockData) {
                return Promise.resolve(mockData)
            }
            const requestOptions = {
                method,
                headers: getHeaders(body ? true : false, isFormData)
            };
            if (body) {
                requestOptions.body = isFormData ? body : JSON.stringify(body)
            }
            return fetch(url, requestOptions).then(handleResponse)
        }
    }

    const getHeaders = (hasData = false, isFormData = false) => {
        const headers = new Headers()
        const token = getToken()
        if (hasData && !isFormData) headers.append("Content-Type", "application/json")
        // if (hasData && isFormData) headers.append("Content-Type", "multipart/form-data");
        if (token) headers.append("acumen-token", `Bearer ${token}`)
        return headers
    }

    const handleResponse = (response) => {
        const contentType = response.headers.get("content-type")
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.text().then(text => {
                const data = text && JSON.parse(text)

                if (!response.ok) {
                    let authFailure = false
                    if ([401].includes(response.status)) {
                        authFailure = true
                    }
                    const error = { description: data?.error?.description || response.statusText }
                    if (data?.data?.errors) {
                        error.errors = data?.data?.errors
                    }
                    if (data?.data?.messages) {
                        error.messages = data?.data?.messages
                    }
                    return Promise.reject({ error, authFailure })
                }
                return Promise.resolve(data)
            });
        } else {
            // 'attachment; filename="Export - Sites List.xlsx"'
            const header = response.headers.get('Content-Disposition')
            let filename
            if (header) {
                const parts = header.split(';')
                filename = parts.length ? parts[1].split("=")[1].replaceAll('"', "") : null
            }

            return [response.blob(), filename]
        }
    }


    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE'),
    };
}

export default RestService()