import React, { Suspense, lazy } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useAuth } from "Auth"
import { Loading } from "./Loading"
import { Modal } from 'Modals/Modal'
import { useAppContext } from './AppContext';
import { Alert, Snackbar } from '@mui/material';
import { useSnackbar } from 'Components/Snackbar';
const Authenticated = lazy(() => import("./Authenticated").then(module => ({ default: module.Authenticated })))
const UnAuthenticated = lazy(() => import("./UnAuthenticated").then(module => ({ default: module.UnAuthenticated })))

function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="body2" color="text.secondary" align="center" style={{ marginTop: 100 }}>
        {'Copyright © '}
        <Link color="inherit" href="https://acumengroup.in" style={{ textDecoration: 'none' }}>
          Acumen
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="overline" component="p" color="text.secondary" align="center">
        Version: {global.appVersion}
      </Typography>
    </React.Fragment>
  );
}

const App = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const { isLoading: isGeneralLoading } = useAppContext()
  const { snackbar, closeSnackbar, autoHideDuration } = useSnackbar()

  if (isLoading) return <Loading />

  return (
    <Container maxWidth={false} disableGutters>
      <Box sx={{ mb: 4, minHeight: '80vh' }}>
        <Suspense fallback={<Loading />}>{isAuthenticated ? <Authenticated /> : <UnAuthenticated />}</Suspense>
      </Box>
      <Copyright />
      <Modal />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbar}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isGeneralLoading ? <Loading /> : null}
    </Container>
  )
}

export { App }
