import { ROLE_CODES } from "Common/Constants";
import { SysBrewAuthProvider, useSysBrewAuth } from "SysBrewAuthProvider";

const AUTH_TOKEN = "auth_token"

const getToken = () => localStorage.getItem(AUTH_TOKEN)
const removeToken = () => localStorage.removeItem(AUTH_TOKEN)

const AuthProvider = ({ children }) => {

    return (
        <SysBrewAuthProvider>
            {children}
        </SysBrewAuthProvider>
    )
}

const throwError = hook => {
    throw new Error(`${hook} must be used within a AuthProvider`)
}

const useAuth = () => {
    const { user, isLoading, error, isAuthenticated, logout: authLogout, token } = useSysBrewAuth()

    if (user === undefined) throwError("useAuth")

    const clearSession = () => {
        removeToken()
    }

    const logout = () => {
        clearSession()
        authLogout({ returnTo: window.location.origin })
    }

    const isAdmin = () => {
        if (!user.role) return false
        return user.role === ROLE_CODES.SUPERADMIN || user.role === ROLE_CODES.ADMIN
    }

    const isSuperAdmin = () => {
        if (!user.role) return false
        return user.role === ROLE_CODES.SUPERADMIN
    }

    const hasReportees = () => {
        if (!user.has_reportees) return false
        return user.has_reportees
    }

    return {
        isLoading,
        error,
        isAuthenticated,
        logout,
        clearSession,
        getAccessToken: token,
        user,
        isSuperAdmin: isSuperAdmin(),
        isAdmin: isAdmin(),
        hasReportees: hasReportees(),
    }
}

export { AuthProvider, useAuth, getToken }