import { useContext, createContext, useState } from "react";

const AppContext = createContext()

const AppProvider = ({ children }) => {

    const [isLoading, showLoader] = useState(false)
    const [shouldReload, setReload] = useState(false)

    return (
        <AppContext.Provider value={{ isLoading, showLoader: () => showLoader(true), hideLoader: () => showLoader(false), shouldReload, setReload }}>
            {children}
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    const {
        isLoading,
        showLoader,
        hideLoader,
        shouldReload,
        setReload
    } = useContext(AppContext)

    return {
        isLoading,
        showLoader,
        hideLoader,
        shouldReload,
        setReload
    }
}

export { AppProvider, useAppContext }